import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBlock from "../components/ContentBlock"

const PageDataRequestPolicy = () => (
  <Layout>
    <PageTitle title="Data Request Policy"></PageTitle>
    <ContentBlock className="rich-text">
      <ol className="spaced list-upper-roman">
        <li>
          <strong>Privacy Policy.</strong> Please see Towbook’s Privacy Policy
          for further information on what type of information Towbook collects,
          how it is used and when it may be disclosed. Subject to Towbook&apos;s
          Privacy Policy, this Data Request Policy describes the types of
          requests for data that Towbook may receive and how Towbook may respond
          to such requests.{" "}
        </li>
        <li>
          <strong>Types of Requests.</strong> Towbook receives the following
          types of requests concerning Client data:
          <ol className="spaced list-lower-alpha">
            <li>
              <strong>Data Requests.</strong> A data request is a request for
              information or documents relating to Client accounts in connection
              with official criminal investigations or other official legal
              proceedings. Except in limited emergency situations (see below),
              Towbook requires data requests to be made through formal U.S.
              legal process and procedures, and we respond to such requests as
              required by law. Examples of data requests include:
              <ol className="spaced list-lower-roman">
                <li>Subpoenas</li>
                <li>Court Orders </li>
                <li>Search Warrants </li>
                <li>
                  Legal process received from outside the United States may
                  require a Mutual Legal Assistance Treaty (MLAT) with the
                  United States{" "}
                </li>
                <li>
                  Other forms of legal process such as Civil Investigative
                  Demands{" "}
                </li>
              </ol>
              *For the purpose of clarity, if you are not the person who signed
              up for Towbook, have had access to the account at any time, are
              named as an additional contact person, or whose name appears on
              invoices or payments to Towbook, you must make a data request as
              provided above.
            </li>
            <li>
              <strong>Preservation Requests.</strong> A preservation request
              asks Towbook to preserve Client account records in connection with
              official criminal investigations or other official legal
              proceedings. Preservation requests must include the following
              information:
              <ol className="spaced list-lower-roman">
                <li>
                  Identification of the account(s) at issue (as further
                  described in Section IV below);{" "}
                </li>
                <li>
                  Identification of the investigating agency and/or specific
                  pending official proceedings (requests must be signed on law
                  enforcement letterhead);{" "}
                </li>
                <li>
                  Assurances that the requesting agency or individual is taking
                  steps to obtain appropriate legal process for access to the
                  data that Towbook is being asked to retain; and{" "}
                </li>
                <li>A valid return email address and phone number. </li>
              </ol>
            </li>
            <li>
              <strong>Emergency Requests.</strong> An emergency request is only
              appropriate in cases involving imminent serious bodily harm or
              death. We respond to emergency requests when we believe in good
              faith that such harm may occur if we do not respond without delay.{" "}
            </li>
          </ol>
        </li>
        <li>
          <strong>Notice to Towbook Clients.</strong> Towbook&apos;s policy is
          to notify its Clients of requests for their data unless Towbook is
          prohibited from doing so by statute or court order. The person who is
          the account owner (person who signed up for Towbook) or person paying
          the monthly fee for Towbook should also be served with a copy by you,
          unless prohibited or not required by applicable law.{" "}
        </li>
        <li>
          <strong>Information to Include in a Request.</strong> The following
          information must be included in a request for Client data:
          <ol className="spaced list-lower-alpha">
            <li>Name of the business </li>
            <li>First and last name of the Client </li>
            <li>
              Specific relevant Client data being sought and specific,
              reasonable period of time{" "}
            </li>
            <li>
              Basis for the proceeding/action in which the Client data is being
              sought and how such data is relevant to that proceeding/action.{" "}
            </li>
          </ol>
          Towbook may not be able to respond to a request without this
          information. Additionally, Towbook reserves the right to request a
          copy of the complaint and any supporting documentation that
          demonstrates how the information requested is related to the pending
          litigation and the underlying subpoena. Towbook also reserves the
          right to charge a reasonable fee, as permitted by applicable law.
        </li>
        <li>
          <strong>Sending a Request to Towbook.</strong> All requests should be
          sent certified mail to:
          <br />
          <br />
          Towbook <br />
          Attn:Data request <br />
          911 Military St, Floor 2<br />
          Port Huron, MI 48060 <br />
          <br />
          with an email copy to{" "}
          <a href="mailto:support@towbook.com">support@towbook.com</a>
          <br />
          <br />
          <p>
            <strong>IMPORTANT NOTE:</strong> IN THE EVENT YOU SEND US A NOTICE
            OF ANY KIND VIA EMAIL AND DO NOT RECEIVE A RESPONSE FROM US, PLEASE
            SUBMIT A DUPLICATE COPY VIA CERTIFIED MAIL/RETURN RECEIPT. DUE TO
            THE VAGARIES OF THE INTERNET AND EMAIL COMMUNICATION IN PARTICULAR,
            INCLUDING WITHOUT LIMITATION THE BURDENS OF SPAM AND THE OCCASIONAL
            UNINTENDED EFFECTS OF SPAM FILTERS, SENDING AN ALTERNATE FORM OF
            NOTICE, WILL HELP ASSURE THAT YOUR NOTICE IS RECEIVED BY US AND
            ACTED ON IN A TIMELY MANNER. IN ADDITION, PLEASE ANTICIPATE
            REASONABLE AMOUNTS OF TIME BETWEEN THE REQUEST AND THE PROVISION OF
            DATA AS ALL REQUESTS MUST GO THROUGH AND BE VETTED BY OUR LEGAL
            DEPARTMENT.{" "}
          </p>
        </li>
        <li>
          <strong>Fees.</strong> Towbook may seek reimbursement for costs in
          responding to Requests as provided by law and may charge additional
          fees for costs in responding to unusual or burdensome requests.{" "}
        </li>
      </ol>
    </ContentBlock>
  </Layout>
)

export default PageDataRequestPolicy
